/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { Button, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { swapTeamIdBetween2Players, changePlayerTeam } from 'redux/slices';

export function SwapPlayersBtn() {
  const players = useSelector((state) => state.players);
  const dispatch = useDispatch();

  const handlePlayerChangeTeam = (playerId1, playerId2) => {
    dispatch(changePlayerTeam({ playerId1, playerId2 }));
    dispatch(swapTeamIdBetween2Players({ playerId1, playerId2 }));
  };

  const swapPlayers = () => {
    // Make a copy of the original teams
    const originalTeamA = players.filter((player) => player.teamId === 1);
    const originalTeamB = players.filter((player) => player.teamId === 2);

    let smallestDifference = Infinity;
    let playerToSwapFromA = null;
    let playerToSwapFromB = null;

    const calculateTeamScore = (team) => team.reduce((total, player) => total + player.wins - player.losses, 0);
    const teamAScore = calculateTeamScore(originalTeamA);
    const teamBScore = calculateTeamScore(originalTeamB);

    // Find the best swap
    originalTeamA.forEach((playerA) => {
      originalTeamB.forEach((playerB) => {
        // Ensure both players are of the same type
        if (playerA.playerType === playerB.playerType) {
          const newTeamAScore = teamAScore - (playerA.wins - playerA.losses) + (playerB.wins - playerB.losses);
          const newTeamBScore = teamBScore - (playerB.wins - playerB.losses) + (playerA.wins - playerA.losses);
          const scoreDifference = Math.abs(newTeamAScore - newTeamBScore);

          if (scoreDifference < smallestDifference) {
            smallestDifference = scoreDifference;
            playerToSwapFromA = playerA;
            playerToSwapFromB = playerB;
          }
        }
      });
    });

    // If a swap is found
    if (playerToSwapFromA && playerToSwapFromB) {
      handlePlayerChangeTeam(playerToSwapFromA.playerId, playerToSwapFromB.playerId);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button variant="contained" onClick={swapPlayers} size="small">
        Trocar 1 jogador de cada time
      </Button>
    </Box>
  );
}

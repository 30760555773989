import { useNavigate } from 'react-router-dom';
import {
  AppBar, Box, Toolbar, Typography, Container, Button, Paper,
} from '@mui/material';
import { blue } from '@mui/material/colors';

const pages = [{ name: 'Marcador', route: '/scoreboard' }, { name: 'Jogadores', route: '/players' }, { name: 'Youtube', route: '/youtube' }];

export function TopBar() {
  const navigate = useNavigate();

  return (
    <Box mb={0}>
      <AppBar
        position="static"
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Toolbar
            disableGutters
          >
            <Typography
              variant="h6"
              noWrap
              onClick={() => navigate('/scoreboard')}
              sx={{
                fontFamily: 'monospace',
                fontWeight: 700,
                fontSize: '1.5rem',
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Volei dos Iniciantes
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Paper elevation={3} sx={{ borderRadius: '0px', bgcolor: blue[500] }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
        >
          {pages.map((page) => (
            <Button
              key={page.name}
              onClick={() => navigate(page.route)}
              sx={{ color: 'white', display: 'block' }}
            >
              {page.name}
            </Button>
          ))}
        </Box>
      </Paper>
    </Box>
  );
}

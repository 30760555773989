import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import { Box, Typography } from '@mui/material';

export function ExplainingIcons() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
        }}
      >
        <SportsVolleyballIcon sx={{ color: 'red' }} />
        <Typography variant="h6">Jogadores marcados com este ícone são veteranos</Typography>
      </Box>
      <Box>
        <br />
      </Box>
    </Box>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState(
  {
    gameStarted: false,
    scoreA: 0,
    scoreB: 0,
    time: { minutes: 0, seconds: 0 },
    playersPerTeam: 6,
    veteransPerTeam: 1,
    pointsHistory: [],
  },
)('game');

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    startGameCounter: (state) => {
      state.gameStarted = true;
    },
    resetScore: (state) => {
      state.scoreA = 0;
      state.scoreB = 0;
    },
    stopGameCounter: (state) => {
      state.gameStarted = false;
      state.scoreA = 0;
      state.scoreB = 0;
      state.time = { minutes: 0, seconds: 0 };
    },
    addScoreToBoard: (state, action) => {
      if (action.payload === 'A') {
        state.scoreA += 1;
      } else {
        state.scoreB += 1;
      }
    },
    cancelScoreToBoard: (state, action) => {
      if (action.payload === 'A' && state.scoreA > 0) {
        state.scoreA -= 1;
      } else if (action.payload === 'B' && state.scoreB > 0) {
        state.scoreB -= 1;
      }
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },
    changePlayersPerTeam: (state, action) => {
      state.playersPerTeam = action.payload;
    },
    changeVeteransPerTeam: (state, action) => {
      state.veteransPerTeam = action.payload;
    },
    setPointsHistory: (state, action) => {
      state.pointsHistory = action.payload;
    },
  },
});

export const {
  startGameCounter,
  stopGameCounter,
  addScoreToBoard,
  cancelScoreToBoard,
  setTime,
  resetScore,
  changePlayersPerTeam,
  changeVeteransPerTeam,
  setPointsHistory,
} = gameSlice.actions;

/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import { Button } from '@mui/material';
import { setPlayerTeam, addPlayerToTeam } from 'redux/slices';
import { useSelector, useDispatch } from 'react-redux';

export function GenerateTwoTeamsBtn() {
  const players = useSelector((state) => state.players);
  const { playersPerTeam, veteransPerTeam } = useSelector((state) => state.game);
  const dispatch = useDispatch();

  const generateTwoTeams = () => {
    const checkedInPlayers = players.filter((player) => player.checkedIn === true);

    const teamA = [];
    const teamB = [];

    const veterans = checkedInPlayers.filter((player) => player.playerType === 'Veteran');
    const beginners = checkedInPlayers.filter((player) => player.playerType === 'Beginner');

    const addPlayersToTeam = (team, count, queue) => {
      const playersToAdd = queue.splice(0, count);
      team.push(...playersToAdd);
    };

    // Add beginners to teams
    addPlayersToTeam(teamA, playersPerTeam - veteransPerTeam, beginners);
    addPlayersToTeam(teamB, playersPerTeam - veteransPerTeam, beginners);

    // Add veterans to teams
    addPlayersToTeam(teamA, veteransPerTeam, veterans);
    addPlayersToTeam(teamB, veteransPerTeam, veterans);

    // Fill remaining spots with more beginners if needed
    if (teamA.length < playersPerTeam) {
      addPlayersToTeam(teamA, playersPerTeam - teamA.length, beginners);
    }
    if (teamB.length < playersPerTeam) {
      addPlayersToTeam(teamB, playersPerTeam - teamB.length, beginners);
    }

    // If there are still spots, fill with veterans
    if (teamA.length < playersPerTeam) {
      addPlayersToTeam(teamA, playersPerTeam - teamA.length, veterans);
    }
    if (teamB.length < playersPerTeam) {
      addPlayersToTeam(teamB, playersPerTeam - teamB.length, veterans);
    }

    teamA.forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 1 }));
      dispatch(addPlayerToTeam({ playerId: player.playerId, teamId: 1 }));
    });
    teamB.forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 2 }));
      dispatch(addPlayerToTeam({ playerId: player.playerId, teamId: 2 }));
    });
  };

  return <Button variant="contained" onClick={generateTwoTeams}>Gerar 2 times</Button>;
}

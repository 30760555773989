/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import { Button } from '@mui/material';
import { setPlayerTeam, addPlayerToTeam } from 'redux/slices';
import { useSelector, useDispatch } from 'react-redux';

export function GenerateBalancedTeamBtn() {
  const players = useSelector((state) => state.players);
  const { playersPerTeam, veteransPerTeam } = useSelector((state) => state.game);
  const dispatch = useDispatch();

  const generateTeam = () => {
    const checkedInPlayers = players.filter((player) => player.checkedIn === true && player.teamId === 0);

    const teamA = players.filter((player) => player.teamId === 1);
    const teamB = players.filter((player) => player.teamId === 2);

    const veteransUnsorted = checkedInPlayers.filter((player) => player.playerType === 'Veteran');
    const beginnersUnsorted = checkedInPlayers.filter((player) => player.playerType === 'Beginner');

    // Sort by higher waitingFor
    let veterans = veteransUnsorted.sort((a, b) => b.waitingFor - a.waitingFor);
    let beginners = beginnersUnsorted.sort((a, b) => b.waitingFor - a.waitingFor);

    const addPlayersToTeam = (team, count, queue) => {
      const playersToAdd = queue.slice(0, count);
      team.push(...playersToAdd);
      return queue.slice(count); // Retorna a fila restante sem os jogadores adicionados
    };

    const noBeginnersWaiting = beginners.every((player) => player.isPlaying === true);

    // Adicionar veteranos ao time primeiro, se houver espaço
    const veteransInTeamA = teamA.filter((player) => player.playerType === 'Veteran').length;
    const veteransNeededInTeamA = Math.min(veteransPerTeam - veteransInTeamA, playersPerTeam - teamA.length);
    if (veteransNeededInTeamA > 0) {
      veterans = addPlayersToTeam(teamA, veteransNeededInTeamA, veterans);
    }

    const veteransInTeamB = teamB.filter((player) => player.playerType === 'Veteran').length;
    const veteransNeededInTeamB = Math.min(veteransPerTeam - veteransInTeamB, playersPerTeam - teamB.length);
    if (veteransNeededInTeamB > 0) {
      veterans = addPlayersToTeam(teamB, veteransNeededInTeamB, veterans);
    }

    // Preencher as vagas restantes com iniciantes
    const beginnersNeededInTeamA = playersPerTeam - teamA.length;
    if (beginnersNeededInTeamA > 0) {
      if (noBeginnersWaiting) {
        veterans = addPlayersToTeam(teamA, beginnersNeededInTeamA, veterans);
      } else {
        beginners = addPlayersToTeam(teamA, beginnersNeededInTeamA, beginners);
      }
    }

    const beginnersNeededInTeamB = playersPerTeam - teamB.length;
    if (beginnersNeededInTeamB > 0) {
      if (noBeginnersWaiting) {
        veterans = addPlayersToTeam(teamB, beginnersNeededInTeamB, veterans);
      } else {
        beginners = addPlayersToTeam(teamB, beginnersNeededInTeamB, beginners);
      }
    }

    // Se ainda houver vagas, preencher com os veteranos restantes
    if (teamA.length < playersPerTeam) {
      addPlayersToTeam(teamA, playersPerTeam - teamA.length, veterans);
    }
    if (teamB.length < playersPerTeam) {
      addPlayersToTeam(teamB, playersPerTeam - teamB.length, veterans);
    }

    // Atualizar os times no estado
    teamA.forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 1 }));
      dispatch(addPlayerToTeam({ playerId: player.playerId, teamId: 1 }));
    });
    teamB.forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: 2 }));
      dispatch(addPlayerToTeam({ playerId: player.playerId, teamId: 2 }));
    });
  };

  return <Button variant="contained" onClick={generateTeam}>Próximos jogadores</Button>;
}

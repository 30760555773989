/* eslint-disable max-len */
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CurrentPlayerCard from './CurrentPlayerCard';
import { GenerateBalancedTeamBtn } from './GenerateBalancedTeamBtn';
import { GenerateTwoTeamsBtn } from './GenerateTwoTeamsBtn';
import { SaveTeamForNextMatchBtn } from './SaveTeamForNextMatchBtn';

export function CurrentPlayers() {
  const players = useSelector((state) => state.players);
  const teams = useSelector((state) => state.teams);
  const gameStarted = useSelector((state) => state.game.gameStarted);
  const teamAMembers = players.filter((p) => p.teamId === 1);
  const teamBMembers = players.filter((p) => p.teamId === 2);

  const noPlayersInTeamA = teamAMembers.length === 0;
  const noPlayersInTeamB = teamBMembers.length === 0;

  const noPlayersInBothTeams = noPlayersInTeamA && noPlayersInTeamB;

  const totalValueTeamA = teamAMembers.reduce((acc, curr) => acc + (curr.wins - curr.losses), 0);
  const totalValueTeamB = teamBMembers.reduce((acc, curr) => acc + (curr.wins - curr.losses), 0);

  const totalPoints = totalValueTeamA + totalValueTeamB;

  const percentageTeamA = totalPoints === 0 ? 0 : Math.round((totalValueTeamA / totalPoints) * 100);
  const percentageTeamB = totalPoints === 0 ? 0 : Math.round((totalValueTeamB / totalPoints) * 100);

  const playerTypeOrder = {
    Veteran: 1,
    Beginners: 2,
  };

  const sortTeamMembers = (teamMembers) => teamMembers.sort((a, b) => playerTypeOrder[a.playerType] - playerTypeOrder[b.playerType]);

  const teamA = sortTeamMembers(teamAMembers);
  const teamB = sortTeamMembers(teamBMembers);

  return (
    noPlayersInBothTeams
      ? (
        <Box
          sx={{
            mt: 4,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <GenerateTwoTeamsBtn />
        </Box>
      )
      : (
        <Box
          sx={{
            mt: 4,
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginRight: 2,
            }}
          >
            <Typography variant="h5" textAlign="center">
              Time A (
              {percentageTeamA}
              %)
              {' '}
              {teams[0].winStrike ? `[Vitórias: ${teams[0].winStrike}]` : ''}
            </Typography>
            {teamA.map((player) => (
              <CurrentPlayerCard key={`${player.playerId}-${player.playerName}`} player={player} />
            ))}
            {noPlayersInTeamA && (<GenerateBalancedTeamBtn />)}
            {!gameStarted && <SaveTeamForNextMatchBtn teamId={1} canSave={!noPlayersInTeamA} />}
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginRight: 2,
            }}
          >
            <Typography variant="h5" textAlign="center">
              Time B (
              {percentageTeamB}
              %)
              {' '}
              {teams[1].winStrike ? `[Vitórias: ${teams[1].winStrike}]` : ''}
            </Typography>
            {teamB.map((player) => (
              <CurrentPlayerCard key={`${player.playerId}-${player.playerName}`} player={player} />
            ))}
            {noPlayersInTeamB && (<GenerateBalancedTeamBtn />)}
            {!gameStarted && <SaveTeamForNextMatchBtn teamId={2} canSave={!noPlayersInTeamB} />}
          </Box>
        </Box>
      )
  );
}

import {
  Counter, StartGameBtn, Score, TopBar, CurrentPlayers, SwapTeamBtn, BalanceTeamsBtn,
  SwapPlayersBtn, ExplainingIcons,
} from 'components';
import { Box } from '@mui/material';

export function Scoreboard() {
  return (
    <Box>
      <TopBar />
      <Score />
      <Counter />
      <StartGameBtn />
      <CurrentPlayers />
      <SwapTeamBtn />
      <SwapPlayersBtn />
      <BalanceTeamsBtn />
      <ExplainingIcons />
    </Box>
  );
}

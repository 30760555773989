import {
  Box, Button, Divider, Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { removePlayer, removePlayerFromTeam, changePlayerType } from 'redux/slices';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import { RemovePlayer } from './RemovePlayer';

export default function CurrentPlayerCard({ player }) {
  const dispatch = useDispatch();
  const { playerType } = player;

  const dispatchPlayerType = (type) => {
    dispatch(changePlayerType({ playerId: player.playerId, playerType: type }));
  };

  const handleClick = () => {
    console.log('playerType', playerType);
    const isBeginner = playerType === 'Beginner';
    if (isBeginner) { return dispatchPlayerType('Veteran'); }
    return dispatchPlayerType('Beginner');
  };

  const handleRemovePlayer = () => {
    dispatch(removePlayer(player.playerId));
    dispatch(removePlayerFromTeam({ teamId: player.teamId, playerId: player.playerId }));
  };

  const isVeteran = player.playerType === 'Veteran';

  return (

    <Box sx={{
      width: { xs: '100%', md: '80%' },
    }}
    >
      <Box
        key={`${player.playerId}-${player.playerName}`}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          borderRadius: '5px',
          padding: '10px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Typography onClick={handleClick} variant="h6">{player.playerName}</Typography>
          {isVeteran && <SportsVolleyballIcon onClick={handleClick} sx={{ marginLeft: '-15px', color: 'red', height: '30px' }} /> }
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={handleRemovePlayer}
          >
            Remover
          </Button>
          <RemovePlayer playerId={player.playerId} isPlaying={handleRemovePlayer} />
        </Box>
      </Box>
      <Divider variant="fullWidth" />
    </Box>
  );
}

import {
  Select, MenuItem, Typography, Box,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeVeteransPerTeam } from 'redux/slices';

export function SelectNumberOfVeteransPerTeam() {
  const { playersPerTeam, veteransPerTeam } = useSelector((state) => state.game);
  const dispatch = useDispatch();
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].filter((option) => option <= playersPerTeam);
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography>Veteranos por time</Typography>
      <Select
        value={veteransPerTeam}
        size="small"
        onChange={(e) => dispatch(changeVeteransPerTeam(e.target.value))}
      >
        {
        options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
            {' '}
            Veteranos
          </MenuItem>
        ))
      }
      </Select>
    </Box>
  );
}
